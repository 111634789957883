import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import logo from 'images/albums-logo.svg';

import styles from '../Header.module.scss';

import LanguageSelector from './LanguageSelector';
import MobileNavigation from './MobileNavigation';

const PublicHeader: React.FC = () => (

  <>
    <MobileNavigation />
    <Link to="/">
      <img className={styles.logo} src={logo} alt="Albums" />
    </Link>
    <nav className={styles.navigation}>
      <Link to="/features" className={styles.navigationLink} activeClassName={styles.navigationLinkActive}>
        <FormattedMessage id="components.header.features" />
      </Link>
      <Link to="/support" className={styles.navigationLink} activeClassName={styles.navigationLinkActive}>
        <FormattedMessage id="components.header.support" />
      </Link>
      <Link to="/about" className={styles.navigationLink} activeClassName={styles.navigationLinkActive}>
        <FormattedMessage id="components.header.about" />
      </Link>
      <a href="https://web.albums.app/login" target={'_blank'} className={styles.navigationLink} rel="noreferrer">
        <FormattedMessage id="components.header.webapp" />
      </a>
      <a href="https://blog.albums.app" className={styles.navigationLink} rel="noreferrer">
        <FormattedMessage id="components.header.blog" />
      </a>
    </nav>
    <LanguageSelector />
  </>

);

export default PublicHeader;
