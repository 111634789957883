import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';
import React from 'react';

import en from './icons/en.svg';
import fr from './icons/fr.svg';

import styles from './LanguageSelector.module.scss';

type SupportedLanguage = 'en' | 'fr';

interface IntlRenderProps {
  languages: SupportedLanguage[];
  language: SupportedLanguage;
}

const LanguageSelector: React.FC = () => (
  <div className={styles.container}>
    <IntlContextConsumer>
      {(intlRenderProps: IntlRenderProps): JSX.Element => {
        const { languages, language } = intlRenderProps;
        const newLanguage = languages.filter((lang) => lang !== language)[0];

        return (
          <div
            className={styles.selector}
            onClick={(): void => changeLocale(newLanguage)}
            onKeyPress={(): void => changeLocale(newLanguage)}
            tabIndex={0}
            role="button"
            aria-label="Change language"
          >
            <img className={styles.language} src={language === 'en' ? fr : en} alt={newLanguage} />
          </div>
        );
      }}
    </IntlContextConsumer>
  </div>
);

export default LanguageSelector;
