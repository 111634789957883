import classNames from 'classnames';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';

import { detectMobileOs } from 'helpers/common';

import InstallButton from 'components/InstallButton';
import logo from 'images/albums-logo-white.svg';

import MenuButton from './MenuButton';

import styles from './MobileNavigation.module.scss';

const MobileNavigation: React.FC = () => {
  const [opened, setOpened] = useState(false);
  const [os, setOs] = useState('');

  useEffect(() => setOs(detectMobileOs()), []);

  return (
    <>
      <MenuButton setOpened={setOpened} action="open" isOpened={opened} />

      <div
        className={classNames(styles.menu, { [styles.menuOpened]: opened })}
        aria-hidden={!opened}
      >
        <div className={styles.header}>
          <MenuButton setOpened={setOpened} action="close" isOpened={opened} />
          <Link to="/">
            <img src={logo} alt="" className={styles.logo} />
          </Link>
          <span />
        </div>
        <nav className={styles.navigation}>
          <Link to="/features" className={styles.navigationLink} activeClassName={styles.navigationLinkActive}>
            <FormattedMessage id="components.header.features" />
          </Link>
          <Link to="/influencer" className={styles.navigationLink} activeClassName={styles.navigationLinkActive}>
            <FormattedMessage id="components.header.influencer" />
          </Link>
        </nav>
        <div className={styles.installButtonContainer}>
          {os === 'ios' ? (
            <InstallButton os="ios">
              <FormattedMessage id="components.header.installAlbumsApp" />
            </InstallButton>
          ) : null}

          {os === 'android' ? (
            <InstallButton os="android">
              <FormattedMessage id="components.header.installAlbumsApp" />
            </InstallButton>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MobileNavigation;
