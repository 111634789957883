import React from 'react';

import PublicHeader from './PublicHeader';

import styles from './Header.module.scss';

const Header: React.FC = () => (
  <header className={styles.container}>
    <div className={styles.header}>
      <PublicHeader />
    </div>
  </header>
);
export default Header;
