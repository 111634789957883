import React from 'react';

import styles from './MenuButton.module.scss';

interface MenuButtonProps {
  setOpened: (opened: boolean) => void;
  isOpened: boolean;
  action: 'open' | 'close';
}

const MenuButton: React.FC<MenuButtonProps> = ({ setOpened, isOpened, action }) => (
  <span
    className={`${styles.menuBtn} ${styles[action]}`}
    role="button"
    onClick={(): void => setOpened(action === 'open')}
    onKeyPress={(): void => setOpened(action === 'open')}
    tabIndex={0}
    aria-label={action === 'open' ? 'Open menu' : 'Close menu'}
    aria-haspopup="menu"
    aria-expanded={isOpened}
  />
);

export default MenuButton;
