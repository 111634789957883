import React from 'react';

import SEO from 'components/seo';

import DownloadAlbums from './DownloadAlbums';
import Footer from './Footer';
import Header from './Header';

import 'bootstrap/dist/css/bootstrap-grid.css';

export interface LayoutProps {
    userLogin?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, userLogin }) => {
  const userIsNotLogin = !userLogin;

  return (
    <>
      <SEO />
      <Header />
      <main>{children}</main>
      {userIsNotLogin && <DownloadAlbums />}
      {userIsNotLogin && <Footer />}
    </>
  );
};

export default Layout;
